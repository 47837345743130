.header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
}

.border {
  border-top: 10px solid #b5d6a7;
  width: 100%;
  margin-top: -10px;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.title .name {
  font-family: 'Dancing Script', cursive;
  font-size: 42px;
  padding: 0 4px;
  /* color: #C0362C; */
}

.title {
  font-family: 'Mate SC', serif;
  font-size: 24px;
  padding-bottom: 8px;
  margin: 0 32px;
}

.title a {
  text-decoration: none;
  color: black;
}

.menu-item {
  display: flex;
  align-items: flex-end;
  font-family: 'Mate SC', serif;
  min-width: 100px;
  border-bottom: 10px solid transparent;
  padding-bottom: 8px;
  margin: 0 8px;
}

.menu-item a {
  text-decoration: none;
  text-align: center;
  color: black;
  font-size: 20px;
  width: 100%;
}

.menu-item.selected {
  border-bottom: 10px solid #556b4c;
  background-color: #f0f0f0;
  z-index: 10;
}

.menu-item:hover:not(.selected) {
  background-color: #f5f5f5;
}

.kebab {
  font-size: 22px;
  position: absolute;
  left: 16px;
  top: 12px;
}

.left-nav {
  position: absolute;
  width: 180px;
  left: -190px;
  top: 10px;
  display: flex;
  flex-direction: row;
  z-index: 1;
}

.left-nav .items {
  flex: 1;
}

@media only screen and (max-width: 850px) {
  .menu-item {
    border-left: 10px solid transparent;
    border-bottom: none;
    margin: 0;
    padding: 8px 16px;
  }

  .menu-item a {
    text-align: start;
  }

  .menu-item.selected {
    border-bottom: none;
    border-left: 10px solid #556b4c;
    background-color: #f0f0f0;
    z-index: 10;
  }

  .left-nav-border {
    border-right: 10px solid #b5d6a7;
    min-height: 100%;
    margin-right: -10px;
  }

  .menu-item:hover:not(.selected) {
    background-color: transparent;
  }
}