.details .text {
  font-size: 16px;
}

.details .content {
  display: flex;
  flex-direction: row;
}

.details .halvsies {
  flex: 1;
}

@media only screen and (max-width: 850px) {
  .details .content {
    flex-direction: column;
  }

  .details .text, .details .map-container {
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-bottom: 16px;
  }

  .map-container > iframe {
    height: 300px !important;
  }

  .details .timeline-item {
    margin-left: 0 !important;
  }
}

.details .text {
  margin-right: 16px;
}

.details .map-container {
  margin-left: 16px;
}

.details iframe {
  border: none;
  height: 100%;
}

.details .map-container.has-rehearsal iframe {
  border: none;
  height: calc(100% - 37px);
}

.details .bullet {
  display: flex;
  flex-direction: row;
}

.details .timeline-item img {
  margin: 0 12px;
  height: 26px;
  margin-bottom: -8px;
}

.details .bullet .value {
  flex: 1;
}

.details .timeline-item {
  margin: 0 0 12px 24px;
}

.details .timeline-item:last-of-type {
  margin: 0 0 0 24px;
}

.details .timeline-item .when, .details .timeline-item .what {
  font-size: 20px;
}

.details .timeline-item .where {
  font-style: italic;
  color: #444;
}

.details .footer-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 48px;
}

.details .footer-image img {
  width: 350px;
}

.details .map-toggle {
  text-align: right;
}
