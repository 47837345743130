.rsvp-page .name-lookup .party-listing {
  width: 200px;
  border: 1px solid #b5d6a7;
  border-radius: 4px;
  margin-bottom: 12px;
  cursor: pointer;
  transition: border-left 0.08s linear;
}

.rsvp-page .name-lookup .party-listing:hover {
  border: 1px solid #556b4c;
  border-left: 10px solid #556b4c;
}

.rsvp-page .name-lookup .party-listing:hover .name-item:not(:last-child) {
  border-bottom: 1px solid #556b4c;
}

.rsvp-page .name-item > span {
  padding: 8px;
  display: inline-block;
  font-family: 'Mate SC', serif;
}

.rsvp-page .name-lookup .party-listing .name-item:not(:last-child) {
  border-bottom: 1px solid #b5d6a7;
}

.rsvp-page .details-form .name-box {
  width: 300px;
  border: 1px solid #b5d6a7;
  border-radius: 4px;
  margin-bottom: 12px;
}

/* .rsvp-page .details-form .name-box:not(.plus-one) {
  cursor: pointer;
}

.rsvp-page .details-form .name-box:not(.plus-one):hover {
  border: 1px solid #556b4c;
} */

.rsvp-page .name-box .name {
  padding: 10px;
  font-size: 18px;
  font-family: 'Mate SC', serif;
  border-bottom: 1px solid #b5d6a7;
}

/* .rsvp-page .details-form .name-box:not(.plus-one):hover .name {
  border-bottom: 1px solid #556b4c;
} */

/* .rsvp-page .name-box .attending {
  padding: 4px;
  text-align: right;
} */

.rsvp-page .name-box .plus-one-input-box {
  border-bottom: 1px solid #b5d6a7;
}

.rsvp-page .name-box label, .rsvp-page .name-box .attending-cb {
  cursor: pointer;
}

.rsvp-page .name-box .options {
  display: flex;
  padding: 4px;
  flex-direction: row;
}

.rsvp-page .options .filler {
  flex: 1;
}

.rsvp-page .options .hidden {
  opacity: 0;
}

.rsvp-page .actions {
  margin-top: 16px;
}

.rsvp-page .actions .button {
  margin-right: 10px;
}

.rsvp-page .confirm {
  max-width: 450px;
}

.rsvp-page a {
  color: #556b4c;
}

.rsvp-page .names-container {
  margin-top: 12px;
}

.rsvp-page .spinner-box {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.rsvp-page input[type="checkbox"], .rsvp-page label {
  cursor: pointer;
}