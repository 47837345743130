.register-page form {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 40px 0;
  align-items: center;
}

.register-page .instructions {
  font-size: 24px;
}

.register-page .input-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.register-page .input-container .flourish {
  width: 70px;
}

.register-page .input-container .flourish.left {
  transform: scaleX(-1);
}

.register-page .spinner {
  height: 37px;
}

.register-page .input-text {
  text-align: center;
}