.image-grid {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.image-grid .row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.image-grid img {
  width: 100%;
  height: 100%;
}


.image-grid .container {
  padding: 8px;
}

@media only screen and (max-width: 850px) {
  .image-grid .row {
    flex-direction: column;
  }
}