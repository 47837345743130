.faq-container {
  display: flex;
  flex-direction: column;
  padding: 0 24px;
}

.faq-item {
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
}

.faq-item .bullet {
  color: #0d468c;
  margin-right: 8px;
  font-size: 22px;
}

.faq-item .q {
  font-size: 22px;
}

.faq-img {
  width: 100%;
  text-align: center;
}

.faq-img img {
  height: 180px;
}

@media only screen and (max-width: 850px) {
  .faq-container {
    padding: 0;
  }
}