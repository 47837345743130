.page.landing-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 48px;
}

.landing-page .contents {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.landing-page .main-image {
  width: 300px;
  border-radius: 5px;
}

.landing-page .main-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
}

.landing-page .where {
  font-size: 20px;
}

.landing-page .when {
  font-size: 36px;
}

.landing-page .separator img {
  width: 360px;
  margin: -40px 0;
}

.landing-page .join {
  font-size: 20px;
}

.landing-page .button {
  margin-top: 16px;
}

.landing-page .rsvp-details {
  margin-top: 8px;
}