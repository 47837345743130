.toggle-container {
  display: inline-block;
}

.toggle {
  border-radius: 6px;
  border: 1px solid #556b4c;
  overflow: hidden;
  display: flex;
  flex-direction: row;
}

.toggle .option {
  padding: 7px 11px;
  font-size: 16px;
  font-family: 'Mate SC', serif;
  font-weight: 700;
  background-color: white;
}

.toggle .option a {
  color: #333;
  text-decoration: none;
}

.toggle .option.selected {
  background-color: #556b4c;
  padding: 7px 11px;
  font-size: 16px;
  font-family: 'Mate SC', serif;
  font-weight: 700;
}

.toggle .option.selected:hover {
  background-color: #698160;
}

.toggle .option:hover {
  background-color: #efefef;
}

.toggle .option.selected a {
  color: white;
  text-decoration: none;
}