.page-content {
  background-color: white;
  width: 1000px;
  -webkit-box-shadow: 0px 0px 7px -4px #000000;
  box-shadow: 0px 0px 7px -4px #000000;
}

.page {
  padding: 12px 32px 48px;
  font-family: 'EB Garamond', serif;
  box-sizing: border-box;
}

input.input-text {
  border: 1px solid #888;
  margin: 12px 8px;
  padding: 8px;
  font-size: 14px;
  font-family: 'GM Garamond', serif;
}

.button {
  background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

button.button {
  background-color: #556b4c;
  border-radius: 6px;
  padding: 8px 12px;
  font-size: 16px;
  font-family: 'Mate SC', serif;
  font-weight: 700;
  color: white;
}

button.button.secondary {
  background-color: white;
  border: 1px solid #556b4c;
  color: #333;
}

button.button:hover {
  background-color: #698160;
}

button.button.secondary:hover {
  background-color: #efefef;
}

@media only screen and (max-width: 999px) {
  .page-content {
    width: 100%;
    min-height: 100vh;
  }
}

@media only screen and (max-width: 850px) {
  .page-content {
    transition: margin-left 0.1s ease-out;
    z-index: 2;
  }

  .page-content.menu-open {
    margin-left: 200px;
  }
}