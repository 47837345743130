
.registry-content {

  /* width: 100%; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.registry-content img {
  margin-top: 22px;
  width: 200px;
}

.registry-content .registry-link {
  margin-top: 8px;
  display: block;
  background-color: #556b4c;
  padding: 7px 11px;
  font-size: 16px;
  font-family: 'Mate SC', serif;
  font-weight: 700;
  border-radius: 6px;
  text-decoration: none;
  color: white;
}

.registry-content .registry-link:hover {
  background-color: #698160;
}