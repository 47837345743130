.page-title {
  font-family: 'EB Garamond', serif;
  font-size: 32px;
  margin: 16px 0;
}

.page-title img {
  width: 70px;
  margin-left: 8px;
}

.page-subtitle {
  font-family: 'EB Garamond', serif;
  font-size: 24px;
  margin: 16px 0 8px;
}